<template>
  <div class="dialog-add-account" v-loading="loading">
    <div class="dialog-add-account__title">
      {{ $t('markData.dialog.title') }}
    </div>

    <div class="dialog-add-account__desc">
      <br>
      <p v-html="$t('markData.dialog.description[0]')" />
      <br>
      <p v-html="$t('markData.dialog.description[1]')" />
      <br>
      <p v-html="$t('markData.dialog.description[2]')" />
      <br>
      <p v-html="$t('markData.dialog.description[3]')" />
      <br>
      <p v-html="$t('markData.dialog.description[4]')" />
      <br>
      <LinkElement class="outside-link"/>
    </div>
   
    <div class="">
      <ButtonElement text="Данные уже внесены" @click-button="closeModalWithSave" class="mark-data-button"/>
    </div>

  </div>
</template>
  
<script>
import ButtonElement from '../../components/elements/ButtonElement'
import LinkElement from '../../components/elements/LinkElement.vue';
import axios from 'axios'

export default {
  name: 'MarkData',
  components: {
    ButtonElement,
    LinkElement
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    closeModalWithSave() {
      axios({ url: process.env.VUE_APP_API_URL + `/auth/mark-data-entered`, method: 'POST' })
        .then(() => {
          this.closeModal()
        })
    },
    closeModal() {
      this.$store.dispatch('Dialog/closeDialog')
    },
  }
}
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.outside-link {
  width: max-content;
}

.mark-data-button {
  background: #27AE60;
  border-color: #27AE60;
  height: 50px;
  width: 260px;
}


/* .dialog-add-account {
  &__data-picker {
    display: flex;
    justify-content: space-between;
  }

  &__data-title {
    font-weight: 700;
  }

  &__button-wrapper {
    display: flex;
    margin-top: 3rem;

    .button {
      margin-left: auto;
    }
  }
} */
</style>
  